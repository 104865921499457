@import '../node_modules/materialize-css/sass/components/color-variables';
$primary-color: color('blue', 'darken-1') !default;
$secondary-color: color('amber', 'base') !default;
@import '../node_modules/materialize-css/sass/materialize.scss';



strong {
  font-weight: bold;
}

nav .brand-logo {
  white-space: nowrap;
}

.blue-text .material-icons {
  color: #2788e5;
}

.page-footer {
  background: #1e88e5 !important;
  padding-top: 0 !important;
}

.footer-copyright {
  background: transparent !important;
}

.input-field .spinner {
  position: absolute;
  top: 15px;
  width: 100%;
  z-index: 9;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
}

.nav-wrapper a.disabled {
  opacity: 0.5;
}

.quill {
  background: #fff;
}

.quill-content {
  h1 {
    font-size: 2em;
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 0.5em;
    margin-top: 0;
  }

  p {
    margin: 0.25em 0;
  }

  ul {
    margin-left: 40px;
    li {
      list-style-type: disc;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .ql-indent-1 {
    margin-left: 3em;
  }

  .ql-indent-2 {
    margin-left: 6em;
  }

  .ql-indent-3 {
    margin-left: 9em;
  }

  .ql-indent-4 {
    margin-left: 12em;
  }

  .ql-indent-5 {
    margin-left: 15em;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-justify {
    text-align: justify;
  }

  iframe.ql-align-center {
    display: block;
    margin: 0 auto;
  }

  iframe {
    width: 100%;
    max-width: 800px;
    height: 500px;
    max-height: 100vh;
  }
}

.dashboard-cards {
  .card-content p {
    min-height: 44px;
  }
}

.question-actions,
.concern-actions {
  .disabled {
    color: #aaa;
    cursor: not-allowed;
  }
}

.collection-item.disabled {
  opacity: 0.3;
}

.rate-us {
  display: block;
  cursor: pointer;
  i {
    display: inline-block;
  }
  .mouse-on {
    display: none;
    color: $secondary-color;
  }
  .mouse-off {
    display: block;
  }
  &:hover {
    .mouse-on {
      display: block;
    }
    .mouse-off {
      display: none;
    }
  }
  > i:hover {
    & ~ i {
      .mouse-on {
        display: none;
      }
      .mouse-off {
        display: block;
      }
    }
  }
}

.main-container {
  margin-top: 20px;
}

.primary-color-text {
  @extend .blue-text, .text-darken-1;
}

.secondary-color-text {
  @extend .amber-text;
}

.primary-color {
  @extend .blue, .darken-1;
}

.secondary-color {
  @extend .amber;
}

.danger {
  @extend .red, .lighten-2, .white-text;
}

.success {
  @extend .green, .lighten-2, .white-text;
}

.danger,
.success {
  font-weight: bold;
}

@media #{$small-and-down} {
  .home-page .btn-large {
    font-size: 2em;
    padding: 10px 30px;
    font-weight: bold;
  }
  .parallax img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    object-position: bottom;
  }
  .parallax-container {
    padding: 0px 0px 220px !important;
  }
  nav .brand-logo {
    font-size: 1.2rem;
  }
  .rate-us {
    i.medium {
      font-size: 3rem;
    }
  }
  .btn-large {
    height: auto;
    line-height: 2;
    padding: 1em 2em;
  }
  .section.home-top {
    margin-top: 0;
    margin-bottom: 0;
    background: #fff;
  }

  h6 {
    font-size: 1.64rem;
  }
}

@media #{$large-and-up} {
  .parallax-container {
    min-height: 450px !important;
  }

  .parallax img {
    max-width: 100%;
    height: 100%;
    object-position: 0 30%;
    object-fit: cover;
  }
}

// Margin helpers
.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

@media print {
  .collapsible-body {
    display: block !important;
  }
  .navbar-fixed nav {
    position: absolute !important;
  }
  .material-tooltip,
  .fixed-action-btn,
  .sidenav-trigger {
    display: none !important;
  }
}


.top-button {
  position: relative;
  text-align: center;
  margin-bottom: 85px;
 }

.share-button {
  width: 285px;
  margin: 20px -120px;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: 25px;
}